import { Link } from 'gatsby'
import React from 'react'
import './sidebar.scss'

import Footer from './footer'
import photo from '../images/photo.jpg'

class Sidebar extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isCollapsed: true,
    }
  }

  toggleCollapsed() {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    })
  }

  collapse() {
    this.setState({
      isCollapsed: true,
    })
  }

  render() {
    return (
      <header id="sidebar">

        <div className="d-none d-lg-block w-100">
          <div className="sidebar-photo">
            <Link to="/">
              <img src={photo} className="rounded-circle" alt="Photo" width="150" />
            </Link>
          </div>
        </div>

        <nav>
          <button onClick={this.toggleCollapsed.bind(this)}>
            <i className="fa fa-bars"></i>
          </button>
          <ul className={this.state.isCollapsed ? 'collapsed' : {}}>
            {
              this.props.menuLinks.map(link =>
                <li key={link.name}>
                  <Link to={link.link} activeClassName="active" onClick={this.collapse.bind(this)}>{link.name}</Link>
                </li>
              )
            }
          </ul>
        </nav>

        <Footer/>
      </header>
    )
  }
}

export default Sidebar
