import React from 'react'
import { Link } from 'gatsby'

const Footer = () => (
  <footer>
    © {new Date().getFullYear()} All Rights Reserved / <Link to="/contact/"><i className="fa fa-envelope-o"/> Contact me</Link>
  </footer>
)

export default Footer
