import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Sidebar from './sidebar'
import 'scss/main.scss'

// http://droitlab.com/html/smith/v1/main/index-1-dark.html
// https://html5up.net/uploads/demos/strata/
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <div id="site-wrapper">
          <Sidebar menuLinks={data.site.siteMetadata.menuLinks}/>

          <main role="main">
            {children}
          </main>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
